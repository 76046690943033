import React from 'react'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider, RequireAuth } from './login/auth'
import ThemeCustomization from './themes/'
import CredentialsLogin from './login/credentials-login'
import EnterEmail from './login/start'
import Callback from './login/callback'
import App from './App'
import Dashboard from './dashboard/index'
import ModuleExplore from './module/explore'
import Lesson from './lesson/index'
import EditLesson from './lesson/edit'
import UpsertModule from './module/upsert'
import ForgotPassword from './login/forgot-password'
import ResetPass from './login/reset-pass'
import Badges from './badges'
import Support from './pages/support'
import DynamicSession, { DynamicSessionRenderer } from './dynamic-session'
import DynamicSessionComplete from './dynamic-session/complete'

import './index.css'
import { UserProvider, RequireRole } from './login/user-context'
import Enrolment from './enrollment'
import Roles from './roles'
import AdminDashboard from './admin'
import GroupReport from './admin/groups/report'
import GroupReportOld from './admin/groups/report_old'
import AddModule from './admin/modules/add-module'
import { Provider } from 'react-redux'
import store from './store'
import ViewModule from './admin/modules/view-module'
import LessonWrapper from './admin/lessons/lesson-wrapper'
import { createRoot } from 'react-dom/client'
import Maintenance from './pages/maintenance'

import AddDynamicSession from './admin/dynamic_sessions/add-dynamic-session'

import AddAssessment from './admin/assessments/add-assessment'
import AddAssessmentQuestion from './admin/assessments/add-assessment-question'
import AssessmentQuestions from './admin/assessments/assessment-questions'
import PracticeArea from 'practice-area'
import NotificationsCenter from './notifications'
import AssessmentSession from './assessment-session'

import EditCompany from './admin/companies/edit-company'
import ViewCompany from './admin/companies/view-company'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <ThemeCustomization>
      <AuthProvider>
        <UserProvider>
          <BrowserRouter>
            {/* <Routes>
            <Route
              path="/*"
              element={<Maintenance />}
            />
          </Routes> */}
            <Routes>
              <Route path="/login" element={<EnterEmail />} />
              <Route path="/credentials-login" element={<CredentialsLogin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/password-reset" element={<ResetPass />} />
              <Route path="/auth/token-callback" element={<Callback />} />
              <Route path="/support" element={<Support />} />

              <Route
                path="/"
                element={
                  <RequireAuth>
                    <App />
                  </RequireAuth>
                }
              >
                <Route index element={<Dashboard />} />
                <Route path="/notifications" element={<NotificationsCenter />} />
                <Route path="module/:moduleId/explore/" element={<ModuleExplore />} />
                <Route path="module/:moduleId/lesson/:lessonId" element={<Lesson />} />
                <Route path="module/:moduleId/lesson/:lessonId/:elementId" element={<Lesson />} />
                <Route path="badges" element={<Badges />} />
                <Route path="practice-area" element={<PracticeArea />} />
                <Route path="/assessment/:assessmentId/" element={<AssessmentSession />} />

                <Route path="/dynamic-session/:sessionId/" element={<DynamicSession />}>
                  <Route path="/dynamic-session/:sessionId/:lessonId/:elementId" element={<DynamicSessionRenderer />} />
                  <Route path="/dynamic-session/:sessionId/complete" element={<DynamicSessionComplete />} />
                </Route>

                <Route
                  path="module/:moduleId/lesson/:lessonId/edit"
                  element={
                    <RequireRole roles={['Admin']}>
                      <EditLesson />
                    </RequireRole>
                  }
                />

                <Route
                  path="/admin-dashboard"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AdminDashboard />
                    </RequireRole>
                  }
                />
                <Route
                  path="/group/:groupId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <GroupReport />
                    </RequireRole>
                  }
                />
                <Route
                  path="/group_old/:groupId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <GroupReportOld />
                    </RequireRole>
                  }
                />
                <Route
                  path="/add-module"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddModule />
                    </RequireRole>
                  }
                />
                <Route
                  path="/edit-module/:moduleId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddModule />
                    </RequireRole>
                  }
                />
                <Route
                  path="/view-module/:moduleId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <ViewModule />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin/lesson/*"
                  element={
                    <RequireRole roles={['Admin']}>
                      <LessonWrapper />
                    </RequireRole>
                  }
                />
                <Route
                  path="/add-dynamic-session"
                  element={
                    <RequireRole roles={['Admin']}>
                      <RequireAuth>
                        <AddDynamicSession />
                      </RequireAuth>
                    </RequireRole>
                  }
                />
                <Route
                  path="/edit-dynamic-session/:sessionId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddDynamicSession />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/create-assessment/"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddAssessment />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/edit-assessment/:assessmentId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddAssessment />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/assessment/:assessmentId/questions"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AssessmentQuestions />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/assessment/:assessmentId/add-question/:questionId"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddAssessmentQuestion />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/assessment/:assessmentId/add-question/"
                  element={
                    <RequireRole roles={['Admin']}>
                      <AddAssessmentQuestion />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/company/create"
                  element={
                    <RequireRole roles={['Admin']}>
                      <EditCompany />
                    </RequireRole>
                  }
                />
                <Route
                  path="/admin-panel/company/:companyId/"
                  element={
                    <RequireRole roles={['Admin']}>
                      <ViewCompany />
                    </RequireRole>
                  }
                />

                <Route
                  path="/admin-panel/company/:companyId/edit"
                  element={
                    <RequireRole roles={['Admin']}>
                      <EditCompany />
                    </RequireRole>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </AuthProvider>
    </ThemeCustomization>
  </Provider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
