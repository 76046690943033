import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, Tabs, Tab, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import GroupsListComponent from './groups/groups-list-component'

const ViewCompany = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const { companyId } = useParams()
  const [tabIndex, setTabIndex] = useState(0)
  const [companyData, setCompanyData] = useState({
    name: '',
    is_active: false,
  })

  const fetchCompanyData = async (companyId) => {
    setLoading(true)
    const data = await axios.get(`/admin/company/${companyId}`)

    return {
      companyId: data.data.uuid,
      name: data.data.name,
      is_active: data.data.is_active,
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchCompanyData(companyId)
        .then((data) => {
          setCompanyData(data)
          setLoading(false)
        })
        .catch((data) => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
          navigate('/admin-dashboard?tab=0')
        })
    } else {
      setLoading(false)
    }
  }, [companyId])

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }
  return (
    <Container display="flex">
      <Typography sx={{ margin: 1 }} variant="h3">
        {companyId ? `Company: ${companyData?.name}` : 'Add Company'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 1, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=0">
          <Button sx={{ padding: '0 3px', marginBottom: 1, fontSize: '0.875rem', minWidth: 'auto' }} variant="text">
            <ArrowBackIcon fontSize="small" />
            Back to Companies
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%', padding: 1 }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}

          <Grid container rowSpacing={1} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Groups" {...a11yProps(0)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabIndex} index={0}>
                  <GroupsListComponent companyId={companyId} />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export default ViewCompany
