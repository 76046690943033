import React, { useState } from 'react'
import axios from 'axios'
import { Modal, Box, Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material'

const CreateGroupModal = ({ open, onClose, companyID, onSave }) => {
  const [groupData, setGroupData] = useState({
    name: '',
    is_active: true,
    leaderboard_active: true,
    proficiency_active: true,
    start_ts: new Date().toISOString().slice(0, 16),
    end_ts: new Date().toISOString().slice(0, 16),
  })
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setGroupData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSave = async () => {
    try {
      await axios.post(`/admin/company/${companyID}/group`, groupData)
      onSave()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%' }}>
        <Typography variant="h6" gutterBottom>
          Create Group
        </Typography>
        {error && <Typography color="error">Error creating group: {error.message}</Typography>}
        <TextField label="Name" name="name" value={groupData.name} onChange={handleChange} fullWidth margin="normal" />
        <FormControlLabel control={<Checkbox checked={groupData.is_active} onChange={handleChange} name="is_active" />} label="Is Active" />
        <FormControlLabel control={<Checkbox checked={groupData.leaderboard_active} onChange={handleChange} name="leaderboard_active" />} label="Leaderboard" />
        <FormControlLabel control={<Checkbox checked={groupData.proficiency_active} onChange={handleChange} name="proficiency_active" />} label="Proficiency Active" />
        <TextField
          label="Start Timestamp"
          name="start_ts"
          type="datetime-local"
          value={groupData.start_ts}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End Timestamp"
          name="end_ts"
          type="datetime-local"
          value={groupData.end_ts}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateGroupModal
