import React, { useState, useEffect } from 'react'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'

const GroupSelect = ({ initialUuid, companyID, onChange }) => {
  const [groupUuid, setGroupUuid] = useState(initialUuid || '')
  const [loadingGroups, setLoadingGroups] = useState(true)
  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (loadingGroups) {
      axios(`/admin/company/${companyID}/group/`).then((r) => {
        setGroups(r.data)
        setLoadingGroups(false)
      })
    }
  }, [loadingGroups, setGroups])

  return loadingGroups ? (
    <CircularProgress />
  ) : (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel id="group">Group</InputLabel>
      <Select
        defaultValue=""
        labelId="group"
        value={groupUuid}
        onChange={(e) => {
          setGroupUuid(e.target.value)
          onChange(e.target.value)
        }}
        autoWidth
      >
        {groups.map(({ name, uuid }) => (
          <MenuItem key={`group-option-${uuid}`} value={uuid}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GroupSelect
