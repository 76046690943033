import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import {
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  FormControl,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Link,
  Button,
  Box,
  MenuItem,
} from '@mui/material'
import EditGroupModal from './edit-group-modal'
import CreateGroupModal from './create-group-modal'
import { useCallback } from 'react'

const GroupsListComponent = () => {
  const { companyId } = useParams()
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState(true)

  const fetchGroups = useCallback(async () => {
    try {
      const response = await axios.get(`/admin/company/${companyId}/group?is_active=${activeFilter}`)
      setGroups(response.data || [])
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [companyId, activeFilter])

  useEffect(() => {
    fetchGroups()
  }, [fetchGroups])

  const handleOpenModal = (groupUUID) => {
    setSelectedGroup(groupUUID)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedGroup(null)
  }

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true)
  }

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false)
  }

  const handleReload = () => {
    // Refresh the groups list after saving
    setLoading(true)
    axios.get(`/admin/company/${companyId}/group`).then((response) => {
      setGroups(response.data || [])
      setLoading(false)
    })
  }

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Typography color="error">Error fetching groups: {error.message}</Typography>
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <ActiveFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
        <Button variant="contained" color="primary" onClick={handleOpenCreateModal} sx={{ padding: '4px 8px', fontSize: '0.875rem', minWidth: 'auto' }}>
          Create Group
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Members</TableCell>
              <TableCell>Reports</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.uuid}>
                <TableCell>
                  <Link component="button" variant="body2" onClick={() => handleOpenModal(group.uuid)}>
                    {group.name}
                  </Link>
                </TableCell>
                <TableCell>{group.is_active ? 'Yes' : 'No'}</TableCell>
                {/* <TableCell>View Members</TableCell>
                <TableCell>View Reports</TableCell> */}
                <TableCell>
                  <Link component="button" variant="body2" onClick={() => handleOpenModal(group.uuid)}>
                    Edit Group
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedGroup && <EditGroupModal open={modalOpen} onClose={handleCloseModal} companyID={companyId} groupUUID={selectedGroup} onSave={handleReload} />}
      <CreateGroupModal open={createModalOpen} onClose={handleCloseCreateModal} companyID={companyId} onSave={handleReload} />
    </>
  )
}

const ActiveFilter = ({ activeFilter, setActiveFilter }) => {
  const handleChange = (event) => {
    setActiveFilter(event.target.value)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter:</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Active</InputLabel>

          <Select defaultValue="" labelId="demo-simple-select-label" id="demo-simple-select" sx={{ minWidth: '200px' }} value={activeFilter || 'active'} onChange={handleChange}>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}
export default GroupsListComponent
