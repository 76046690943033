import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth } from './auth'
import { Navigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import mixpanel from 'mixpanel-browser'
export const UserContext = React.createContext(null)

export const UserProvider = ({ children }) => {
  const { loggedIn } = useAuth()
  const [roles, setRoles] = useState([])
  const [group, setGroup] = useState(false)
  const [groups, setGroups] = useState([])
  const [name, setName] = useState('')
  const [uuid, setUUID] = useState('')
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState({})
  const [knockToken, setKnockToken] = useState(null)

  useEffect(() => {
    if (loading && loggedIn) {
      axios('/user/self').then((r) => {
        setRoles([...r.data.roles])
        setGroup(r.data.group)
        setGroups(r.data.groups)
        setName(r.data.name)
        setUUID(r.data.uuid)
        setUsername(r.data.username)
        setLoading(false)
        setStats(r.data.stats)
        setKnockToken(r.data.knockToken)

        mixpanel.identify(r.data.username)
        mixpanel.people.set({ $name: r.data.name, group_id: r.data.group.uuid })
        mixpanel.people.set({ $name: r.data.name })
      })
    }
  }, [loading, loggedIn])

  return (
    <UserContext.Provider
      value={{
        uuid,
        group,
        groups,
        loading,
        name,
        roles,
        username,
        stats,
        setStats,
        knockToken,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return React.useContext(UserContext)
}

export const RequireRole = (props) => {
  const { loading, roles } = useUser()

  if (loading) {
    return <CircularProgress />
  }
  if (!loading && props.roles.every((role) => roles.indexOf(role) !== -1)) {
    return props.children
  }

  return <Navigate to="/" />
}
