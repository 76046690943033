import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'

const GroupFilter = ({ groups, filter, setFilter }) => {
  const [selectedGroupFilter, setSelectedGroup] = useState('all')
  const handleChange = (event) => {
    if (event.target.value === 'all') {
      setFilter('')
      setSelectedGroup('')
    } else {
      setSelectedGroup(event.target.value)
      setFilter(event.target.value)
    }
  }

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Group Filter:</Typography>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Group</InputLabel>

          <Select
            defaultValue="all"
            sx={{ minWidth: '200px' }}
            labelId="group-simple-select-label"
            id="group-simple-select"
            value={selectedGroupFilter || 'all'}
            onChange={handleChange}
          >
            <MenuItem value="all">All Groups</MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default GroupFilter
