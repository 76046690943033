import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material'

const MultiGroupSelect = ({ availableGroups, selectedGroupIDs, onChange }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    onChange(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <InputLabel id="group">Groups</InputLabel>
      <Select
        labelId="group"
        value={selectedGroupIDs}
        onChange={handleChange}
        multiple
        renderValue={(selected) => selected.map((uuid) => availableGroups.find((group) => group.uuid === uuid)?.name).join(', ')}
        autoWidth
      >
        {availableGroups.map(({ name, uuid }) => (
          <MenuItem key={`group-option-${uuid}`} value={uuid}>
            <Checkbox checked={selectedGroupIDs.some((groupID) => groupID === uuid)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiGroupSelect
