import React, { useState, useEffect } from 'react'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'

const CompanyFilter = ({ companies, filter, setFilter }) => {
  const [companyFilter, setCompanyFilter] = useState('Choose Company')

  const handleChange = (event) => {
    setCompanyFilter(event.target.value)
    setFilter(event.target.value)
  }

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Company Filter:</Typography>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Company</InputLabel>

          <Select defaultValue="" sx={{ minWidth: '200px' }} labelId="demo-simple-select-label" id="demo-simple-select" value={filter || ''} onChange={handleChange}>
            {companies.map((company) => (
              <MenuItem key={company.uuid} value={company.uuid}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default CompanyFilter
