import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import { FastField, Form, Formik } from 'formik'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const AddCompanySchema = Yup.object().shape({
  name: Yup.string().trim().required('Company Name is required!'),
  is_active: Yup.boolean().required('Is Active is required!'),
})

const EditCompany = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { companyId } = useParams()

  const [companyData, setCompanyData] = useState({
    name: '',
    is_active: false,
  })

  const fetchCompanyData = async (companyId) => {
    setLoading(true)
    const data = await axios.get(`/admin/company/${companyId}`)

    return {
      name: data.data.name,
      is_active: data.data.is_active,
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchCompanyData(companyId)
        .then((data) => {
          setCompanyData(data)
          setLoading(false)
        })
        .catch((data) => {
          setLoading(false)
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
        })
    } else {
      setLoading(false)
    }
  }, [companyId])

  const handleDeleteCompany = async () => {
    if (companyId) {
      const confirmDelete = window.confirm('Are you sure you want to delete this company? This will prevents students of this company from logging in!')
      if (confirmDelete) {
        try {
          await axios.delete(`/admin/company/${companyId}`)
          enqueueSnackbar('Company Deleted Successfully!', { variant: 'success' })
          navigate('/admin-dashboard?tab=0')
        } catch (e) {
          enqueueSnackbar('Something went wrong when trying to delete the company!', { variant: 'error' })
        }
      }
    }
  }

  return (
    <Container display="flex">
      <Typography sx={{ margin: 1 }} variant="h3">
        {companyId ? `Edit Company: ${companyData?.name}` : 'Add Company'}
      </Typography>
      <Box sx={{ background: '#ffffff', padding: 5, position: 'relative' }}>
        <Link variant="body1" to="/admin-dashboard?tab=0">
          <Button sx={{ padding: '0 3px', marginBottom: 1, fontSize: '0.875rem', minWidth: 'auto' }} variant="text">
            <ArrowBackIcon fontSize="small" />
            Back to Companies
          </Button>
        </Link>
        <br />
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Backdrop
              sx={{
                background: 'rgba(255, 255, 255, 0.8)',
                color: '#ffffff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: 'absolute',
              }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Formik
            initialValues={companyData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddCompanySchema}
            onSubmit={async (values) => {
              try {
                setLoading(true)

                const requestData = {
                  name: values.name,
                  // description: values.description,
                  is_active: values.is_active,
                }

                let url = ''
                if (companyId) {
                  requestData['uuid'] = companyId
                  url = `/admin/company/${companyId}`
                  await axios.put(url, requestData)
                } else {
                  url = `/admin/company/`
                  await axios.post(url, requestData)
                }

                let snackbarMessage = 'Company Added Successfully!'

                if (companyId) {
                  snackbarMessage = 'Company Updated Successfully!'
                }

                enqueueSnackbar(snackbarMessage, { variant: 'success' })
              } catch (e) {
                enqueueSnackbar('Something went wrong!', { variant: 'error' })
              }

              setLoading(false)
              navigate('/admin-dashboard?tab=0')
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue, validateField }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={3} direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Grid item xs={12}>
                    <FastField name="name">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="name"
                          label="Company Name"
                          error={Boolean(errors.name && touched.name)}
                          helperText={errors.name && touched.name ? String(errors.name) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FastField name="description">
                      {({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          name="description"
                          label="Assessment description"
                          multiline
                          maxRows={4}
                          error={Boolean(errors.description && touched.description)}
                          helperText={errors.description && touched.description ? String(errors.description) : undefined}
                        />
                      )}
                    </FastField>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <InputLabel id="is_active">Is Company Active</InputLabel>
                      </Grid>
                      <Grid item>
                        <FastField name="is_active">{({ field }) => <Checkbox checked={values.is_active} {...field} name="is_active" label="Is Group Active?" />}</FastField>
                      </Grid>
                    </Grid>
                    <FormHelperText>Setting to false will prevent users from logging in</FormHelperText>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container gap={1} direction="row" justifyContent="space-between">
                      {companyId && (
                        <Grid item>
                          <Button onClick={handleDeleteCompany} variant="delete" type="button" color="error">
                            Delete Company
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Grid container gap={1} direction="row" justifyContent="flex-end">
                          <Grid item>
                            <Link className="top-bar-button" to="/admin-dashboard?tab=0">
                              <Button variant="contained" type="reset">
                                Cancel
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" type="submit">
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  )
}

export default EditCompany
