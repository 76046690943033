import React, { useEffect, useState, useCallback } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Button, IconButton, Menu, MenuItem, Stack, FormControl, Divider, Typography, InputLabel, Select } from '@mui/material'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import axios from 'axios'

const ActiveFilter = ({ activeFilter, setActiveFilter, getCompaniesAPI }) => {
  const handleChange = (event) => {
    setActiveFilter(event.target.value)
    getCompaniesAPI(event.target.value)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography>Filter:</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Active</InputLabel>

          <Select defaultValue="" labelId="demo-simple-select-label" id="demo-simple-select" sx={{ minWidth: '200px' }} value={activeFilter || 'active'} onChange={handleChange}>
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

const ActionMenu = ({ param }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id={`basic-button-${param.row.id}`}
        aria-controls={open ? `basic-menu-${param.row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label="actions"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`basic-menu-${param.row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${param.row.id}`,
        }}
      >
        <Link style={{ textDecoration: 'none' }} to={`/admin-panel/company/${param.row.id}`}>
          <MenuItem onClick={handleClose}>View Company</MenuItem>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={`/admin-panel/company/${param.row.id}/edit`}>
          <MenuItem onClick={handleClose}>Add / Edit Groups</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}

const ManageCompanies = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeFilter, setActiveFilter] = useState(true)

  const getCompaniesAPI = useCallback(
    (filter = activeFilter) => {
      axios(`/admin/company?is_active=${filter}`).then((r) => {
        const tableData = r.data.map((company, key) => {
          return {
            key: key + 1,
            id: company.uuid,
            name: company.name,
            is_active: company.is_active,
          }
        })

        setTableData(tableData)
      })
    },
    [activeFilter]
  )

  useEffect(() => {
    if (loading && activeFilter) {
      setLoading(false)
      getCompaniesAPI()
    }
  }, [loading, activeFilter])

  const columns = [
    { field: 'key', headerName: '#', headerAlign: 'center', align: 'center', type: 'number', filterable: false },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Link to={`/admin-panel/company/${params.row.id}`} color="primary" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginRight: 1, '&:hover': { textDecoration: 'underline' } }}>
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'is_active',
      headerName: 'Active',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (param) => {
        return <ActionMenu param={param} />
      },
    },
  ]

  return (
    <div>
      <Stack direction="row" spacing={2} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />} sx={{ margin: 1 }} flexWrap="wrap">
        <Typography variant="h3">Companies</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {!loading && <ActiveFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} getCompaniesAPI={getCompaniesAPI} />}
        <Link className="top-bar-button" to="/admin-panel/company/create">
          <Button variant="contained" sx={{ padding: '4px 8px', fontSize: '0.875rem', minWidth: 'auto' }}>
            Create Company
          </Button>
        </Link>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <DataGrid
          onCellClick={() => null}
          onCellDoubleClick={() => null}
          onCellFocusOut={() => null}
          onRowClick={() => null}
          onColumnHeaderClick={() => null}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 200 },
            },
          }}
          rows={tableData}
          columns={columns}
          pageSizeOptions={[10, 25, 100]}
          loading={loading}
          autoHeight={loading}
        />
      </Box>
    </div>
  )
}

export default ManageCompanies
