import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Box, Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

const EditGroupModal = ({ open, onClose, companyID, groupUUID, onSave }) => {
  const [groupData, setGroupData] = useState({
    name: '',
    is_active: false,
    start_ts: '',
    end_ts: '',
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    if (open) {
      const fetchGroupDetails = async () => {
        try {
          const response = await axios.get(`/admin/company/${companyID}/group/${groupUUID}`)
          const data = response.data
          setGroupData({
            ...data,
            start_ts: data.start_ts ? new Date(data.start_ts).toISOString().slice(0, 16) : '',
            end_ts: data.end_ts ? new Date(data.end_ts).toISOString().slice(0, 16) : '',
          })
        } catch (err) {
          setError(err)
        } finally {
          setLoading(false)
        }
      }

      fetchGroupDetails()
    }
  }, [open, companyID, groupUUID])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setGroupData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSave = async () => {
    try {
      await axios.put(`/admin/company/${companyID}/group/${groupUUID}`, groupData)
      onSave()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  const handleDeleteGroup = async () => {
    if (groupUUID) {
      const confirmDelete = window.confirm('Are you sure you want to delete this group? Users in this group will not be able to login anymore. Reporting data will be lost!')
      if (confirmDelete) {
        try {
          await axios.delete(`/admin/company/${companyID}/group/${groupUUID}`)
          enqueueSnackbar('Group Deleted Successfully!', { variant: 'success' })
          onSave()
          onClose()
        } catch (e) {
          enqueueSnackbar('Something went wrong when trying to delete the group!', { variant: 'error' })
        }
      }
    }
  }

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  if (error) {
    return <Typography color="error">Error fetching group details: {error.message}</Typography>
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%' }}>
        <Typography variant="h6" gutterBottom>
          Edit Group
        </Typography>
        <TextField label="Name" name="name" value={groupData.name} onChange={handleChange} fullWidth margin="normal" />
        <FormControlLabel control={<Checkbox checked={groupData.is_active} onChange={handleChange} name="is_active" />} label="Is Active" />
        <FormControlLabel control={<Checkbox checked={groupData.leaderboard_active} onChange={handleChange} name="leaderboard_active" />} label="Leaderboard" />
        <FormControlLabel control={<Checkbox checked={groupData.proficiency_active} onChange={handleChange} name="proficiency_active" />} label="Proficiency Active" />
        <TextField
          label="Start Time"
          name="start_ts"
          type="datetime-local"
          value={groupData.start_ts}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="End Time"
          name="end_ts"
          type="datetime-local"
          value={groupData.end_ts}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          {groupUUID && (
            <Button onClick={handleDeleteGroup} variant="delete" type="button" color="error" sx={{ marginRight: 2 }}>
              Delete
            </Button>
          )}
          <Box>
            <Button onClick={onClose} sx={{ marginRight: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditGroupModal
