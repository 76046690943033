import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Skeleton, Stack, Button, Link } from '@mui/material'
import axios from 'axios'

import { useUser } from '../login/user-context'
import Modules from './modules'
import Leaderboard from './leaderboard'
import ProgressChart from 'components/ProgressChart'
import WelcomeModal from './welcome-modal'
import { BadgesCard } from '../badges/index'
import ProficiencyPanel from 'components/proficiency-panel'
import ModuleCompletedPanel from 'components/module-completed-panel'
import AssessmentCompletedPanel from 'components/assessment-completed-panel'
import PieChart from 'components/PieChart'
import MainCard from '../components/MainCard'
import mixpanel from 'mixpanel-browser'
import { useNavigate } from 'react-router-dom'
import JumpBackInModel from './jump-back-in-modal'
import StarIcon from '@mui/icons-material/Star'

const Dashboard = (props) => {
  const navigate = useNavigate()
  const { loading: leaderboardLoading, group, groups, name, stats } = useUser()
  const [dynamicSessions, setDynamicSessions] = useState([])
  const [loadingSessions, setLoadingSessions] = useState(true)
  const [assessments, setAssessments] = useState([])
  const [loadingAssessments, setLoadingAssessments] = useState(true)
  const [loadingModules, setLoadingModules] = useState(true)
  const [modules, setModules] = useState([])
  const [errorModuleLoad, setErrorModuleLoad] = useState(false)
  const showLeaderboard = !leaderboardLoading && group && group.leaderboardActive
  const showProficiencyPanel = !leaderboardLoading && group && group.proficiencyActive

  useEffect(() => {
    if (loadingModules) {
      axios('/module')
        .then((r) => {
          setModules(r.data)
          setLoadingModules(false)
        })
        .catch((e) => {
          setErrorModuleLoad(e)
          setLoadingModules(false)
        })
    }
  }, [loadingModules])

  useEffect(() => {
    if (groups && groups.length > 0 && loadingSessions) {
      fetchDynamicSessionsForGroups(groups.map((group) => group.group_uuid))
      setLoadingSessions(false)
    }
  }, [groups, loadingSessions])

  async function fetchDynamicSessionsForGroups(groupUuids) {
    try {
      const promises = groupUuids.map((group_uuid) => axios(`/dynamic-session/group/${group_uuid}`))
      const responses = await Promise.all(promises)
      const combineResults = responses.map((response) => response.data)
      const flattenResults = combineResults.flat()
      setDynamicSessions(flattenResults)
      setLoadingSessions(false)
    } catch (error) {
      console.error('Error fetching dynamic sessions:', error)
      setLoadingSessions(false)
    }
  }

  useEffect(() => {
    if (groups && groups.length > 0 && loadingAssessments) {
      fetchAssessmentsForGroups(groups.map((group) => group.group_uuid))
      setLoadingAssessments(false)
    }
  }, [groups, loadingAssessments])

  async function fetchAssessmentsForGroups(groupUuids) {
    try {
      const promises = groupUuids.map((group_uuid) => axios(`/assessment/group/${group_uuid}`))
      const responses = await Promise.all(promises)
      const combineResults = responses.map((response) => response.data)
      const flattenResults = combineResults.flat()
      setAssessments(flattenResults)
      setLoadingAssessments(false)
    } catch (error) {
      setLoadingAssessments(false)
      console.error('Error fetching dynamic sessions:', error)
    }
  }

  return (
    <Box sx={{ paddingBottom: '35px' }}>
      <WelcomeModal />
      <JumpBackInModel modules={modules} />

      <Modules
        className="step_modules_panel"
        dynamicSessions={dynamicSessions}
        assessments={assessments}
        modules={modules}
        modulesLoaded={!loadingModules}
        errorModuleLoad={errorModuleLoad}
      />

      {leaderboardLoading ? (
        <Grid container spacing={2} direction="row" sx={{ marginTop: '35px' }}>
          <Grid item xs={6} md={6} lg={6}>
            <Box>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="rectangular" height={300} />
            </Box>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Box>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="rectangular" height={300} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} direction="row" sx={{ marginTop: '35px' }}>
          {/* {modules.length > 1 && (
            <Grid item xs={12} md={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Your Modules</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <MainCard className="step_module_completed_panel" sx={{ mt: 2, minHeight: '350px' }} content={false}>
                <ModuleCompletedPanel modules={modules} />
              </MainCard>
            </Grid>
          )} */}

          <Grid item xs={12} md={4} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3">Test Performance</Typography>
              </Grid>
              <Grid item />
            </Grid>

            <MainCard sx={{ mt: 2, minHeight: '350px' }} content={false}>
              <PieChart stats={stats} />
              <Link
                sx={{ display: 'block', textAlign: 'center' }}
                href="/practice-area"
                onClick={(event) => {
                  event.preventDefault()
                  mixpanel.track('button_clicked', {
                    Feature: 'practice_area',
                    Location: 'dashboard_test_performance',
                    Name: 'Retry Incorrect Questions',
                  })
                  navigate('/practice-area')
                }}
              >
                <Button sx={{ mb: 2, mt: 2, color: 'theme.primary.main', borderColor: 'theme.primary.main', borderWidth: '2px', borderStyle: 'solid' }}>
                  {' '}
                  <StarIcon
                    sx={{
                      mr: 1,
                    }}
                  />{' '}
                  Retry Incorrect Questions
                </Button>
              </Link>
            </MainCard>
          </Grid>

          {assessments.length >= 1 && (
            <Grid className="step_your_assessments_panel" item xs={12} md={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Your Assessments</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <MainCard sx={{ mt: 2, minHeight: '350px' }} content={false}>
                <AssessmentCompletedPanel assessments={assessments} />
              </MainCard>
            </Grid>
          )}
          {showProficiencyPanel && (
            <Grid item xs={12} md={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Proficiency Achievements</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <MainCard sx={{ mt: 2, minHeight: '350px' }} content={false}>
                <ProficiencyPanel />
              </MainCard>
            </Grid>
          )}

          {showLeaderboard && (
            <Grid item xs={12} md={4} lg={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">Leaderboard</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <MainCard sx={{ mt: 2, minHeight: '350px' }} content={false}>
                <Leaderboard />
              </MainCard>
            </Grid>
          )}
          <Grid item xs={12} md={4} lg={4} sx={{ minHeight: '350px' }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3">Badges</Typography>
              </Grid>
              <Grid item />
            </Grid>

            <MainCard sx={{ mt: 2 }} content={false}>
              <BadgesCard />
            </MainCard>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Dashboard
